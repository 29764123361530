import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/components/hamburger.scss";

const HambergerNavigation = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen); // 햄버거 메뉴 상태 토글
    };

    return (
        <>
            <button className={`hamburger ${isOpen ? "hamburger--active" : ""}`} onClick={handleClick}>
                <span className="hamburger__box">
                    <span className="hamburger__inner"></span>
                </span>
            </button>
            <div className={`navigation ${isOpen ? "navigation--active" : ""}`}>
                <ul className="navigation__list">
                    <li className="navigation__item">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="navigation__item">
                        <Link to="/main">Overview</Link>
                    </li>
                    <li className="navigation__item">
                        <Link to="/realtimeEdr">Real-Time EDR Data</Link>
                    </li>
                    <li className="navigation__item">
                        <a
                            href="https://baobab.klaytnscope.com/account/0xc2887c412905eF5e395B319e3E9f20C79F4E14e8?tabId=txList"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Blockchain Transaction
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export const runtime = "edge";

export default HambergerNavigation;
