import React from 'react';
import Web3 from 'web3';
import { useNavigate } from 'react-router-dom';
import '../styles/pages/landing.css';
import BlockAnimate from '../components/blockAnimate';

const Landing = () => {
    const navigate = useNavigate(); // useNavigate 훅을 호출합니다.

    const connectToKaikas = async () => {
        if (window.klaytn) {
            try {
                await window.klaytn.enable();
                new Web3(window.klaytn);
                // 지갑 연결에 성공하면 /main 페이지로 이동합니다.
                navigate('/main');
            } catch (error) {
                // 지갑 연결 실패 시, 에러 처리를 할 수 있습니다.
                alert('지갑 연결에 실패했습니다.');
                console.log(error);
            }
        } else {
            // Kaikas가 설치되어 있지 않은 경우, 사용자에게 알림을 줄 수 있습니다.
            alert('Kaikas 확장 프로그램을 설치해주세요.');
        }
    };

    return (
        <>
            <div className="landingContainer">
                <div className="landingTitle">
                    Blockchain-based<br />EV sudden acceleration<br />Prevention system
                </div>
                <BlockAnimate />
                <button className="loginButton" onClick={connectToKaikas}>Connect Kaikas wallet</button>
            </div>
        </>
    )
};

export default Landing;
