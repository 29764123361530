import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import styles from '../styles/pages/preCrash.module.css';
import Chart from '../components/chart';
import Nav from '../components/nav';
import axios from 'axios';

const CONTRACT_ADDRESS = '0xc2887c412905eF5e395B319e3E9f20C79F4E14e8';
const ABI = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "ipfsHash",
                "type": "string"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "IPFSHashAdded",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "ipfsEntries",
        "outputs": [
            {
                "internalType": "string",
                "name": "ipfsHash",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "userIpfsEntries",
        "outputs": [
            {
                "internalType": "string",
                "name": "ipfsHash",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_ipfsHash",
                "type": "string"
            }
        ],
        "name": "setHash",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getIPFSEntriesCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
            }
        ],
        "name": "getUserIPFSEntriesCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
            }
        ],
        "name": "getHash",
        "outputs": [
            {
                "internalType": "string[]",
                "name": "",
                "type": "string[]"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    }
]; // YOUR CONTRACT ABI


const RealTimeEdr = () => {
    const [acPedal, setAcPedal] = useState([]);
    const [data, setData] = useState([]); //brake pedal
    const [engineRpm, setEngineRpm] = useState([]);
    const [engineThrottle, setEngineThrottle] = useState([]);
    const [vehicleSpeed, setVehicleSpeed] = useState([]);
    const [isConnected, setIsConnected] = useState(false);
    const [web3, setWeb3] = useState(null);
    const [myWalletAddress, setMyWalletAddress] = useState("");

    const connectToKaikas = async () => {
        if (window.klaytn) {
            try {
                const accounts = await window.klaytn.enable();
                // console.log("Connected accounts: ", accounts);
                const web3Instance = new Web3(window.klaytn);
                setWeb3(web3Instance);
                setIsConnected(!!accounts.length);
                setMyWalletAddress(accounts[0]);
                // window.klaytn.on('accountsChanged', () => {
                //     window.location.reload();
                // });
            } catch (error) {
                console.error("Failed to connect Kaikas", error);
                setIsConnected(false);
            }
        } else {
            console.log('No Kaikas extension detected!');
            setIsConnected(false);
        }
    };

    const disconnectKaikas = () => {
        // window.klaytn.disable(); // Or any logic to disconnect Kaikas
        setWeb3(null);
        setData([]);
        setIsConnected(false);
    };

    useEffect(() => {
        // Kaikas 연동
        if (window.klaytn) {
            // Kaikas의 BApp용 provider 사용
            const web3Instance = new Web3(window.klaytn);
            setWeb3(web3Instance);
            if (window.klaytn.isConnected()) {
                setIsConnected(true);
                setMyWalletAddress(window.klaytn.selectedAddress);
            }

            // Kaikas 계정 변경 시 화면 갱신
            // window.klaytn.on('accountsChanged', () => {
            //     window.location.reload();
            // });
            window.klaytn.on('accountsChanged', (accounts) => {
                if (accounts.length) {
                    setMyWalletAddress(accounts[0]);
                } else {
                    setIsConnected(false);
                }
            });
        } else {
            console.log('No Kaikas extension detected!');
        }
    }, []);
    const fetchDataFromIPFS = async (hash) => {
        try {
            const response = await axios.get(`https://gateway.pinata.cloud/ipfs/${hash}`);
            // console.log(`Data fetched from IPFS for hash ${hash}: `, response.data); 
            return response.data;
        } catch (error) {
            console.error(`Error fetching data for hash: ${hash}`, error);
            return null;
        }
    };

    useEffect(() => {
        const fetchDataFromContract = async () => {
            if (!web3) return;

            try {
                const accounts = await window.klaytn.enable();  // Kaikas에서 사용자 주소 얻기
                // console.log("Fetch data for account: ", accounts[0]);
                const contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);

                // Get hash array from contract
                let hashArray = await contract.methods.getHash(accounts[0]).call();
                // 첫 번째와 두 번째 요소를 제외한 나머지 배열
                hashArray = hashArray.slice(2);
                // console.log("Hash Array from Contract: ", hashArray);

                // Fetch data from IPFS using hash and update state
                const dataPromises = hashArray.map(hash => fetchDataFromIPFS(hash));
                const results = await Promise.all(dataPromises);

                // 필터링하여 null 값을 제거하고 오류가 없는 데이터만 상태에 설정
                const validData = results.filter(item => item !== null);

                // 데이터를 시간순으로 정렬
                validData.sort((b, a) => {
                    // "YYYY.MM.DD_HH:mm:ss" 형식의 문자열을 날짜 객체로 파싱
                    const timeA = a.time.split('_');
                    const partsA = timeA[0].split('.').map(Number);
                    const dateTimeA = new Date(partsA[0] + 2000, partsA[1] - 1, partsA[2], ...timeA[1].split(':').map(Number));

                    const timeB = b.time.split('_');
                    const partsB = timeB[0].split('.').map(Number);
                    const dateTimeB = new Date(partsB[0] + 2000, partsB[1] - 1, partsB[2], ...timeB[1].split(':').map(Number));

                    return dateTimeA - dateTimeB;
                });



                setData(validData);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        if (web3) {
            fetchDataFromContract();
        }
        // if (web3) {
        //   fetchDataFromContract(); // 컴포넌트 마운트 시 즉시 데이터를 한 번 가져옵니다.
        //   const intervalId = setInterval(fetchDataFromContract, 1000); // 5초마다 fetchDataFromContract 함수를 반복 호출합니다.

        //   return () => clearInterval(intervalId); // 컴포넌트가 언마운트되면 인터벌을 정리합니다.
        // }
    }, [web3]);


    useEffect(() => {


        const intervalId = setInterval(() => {
            const currentTime = new Date();
            const formattedTime = `${currentTime.getFullYear().toString().slice(-2)}.${(currentTime.getMonth() + 1).toString().padStart(2, '0')}.${currentTime.getDate().toString().padStart(2, '0')}_${currentTime.getHours().toString().padStart(2, '0')}:${currentTime.getMinutes().toString().padStart(2, '0')}:${currentTime.getSeconds().toString().padStart(2, '0')}`;

            setAcPedal(prev => [...prev, { time: formattedTime, voltage: Math.random() * (80 - 10) + 10 }]);
            setEngineRpm(prev => [...prev, { time: formattedTime, voltage: Math.random() * (2000 - 1000) + 1000 }]);
            setEngineThrottle(prev => [...prev, { time: formattedTime, voltage: Math.random() * (18 - 14) + 14 }]);
            setVehicleSpeed(prev => [...prev, { time: formattedTime, voltage: Math.random() * (100 - 10) + 10 }]);
        }, 1000);

        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 정리
    }, []);




    return (
        <>
            <div className={styles.navContainer}>
                <Nav isConnected={isConnected} connectToKaikas={connectToKaikas} disconnectKaikas={disconnectKaikas} myWalletAddress={myWalletAddress} />
            </div>
            <div className={styles.container}>
                <div className={styles.title} >Real-Time EDR Data</div>
                <Chart data={acPedal} title={'Acclerator Pedal [%]'} ymin={0} ymax={100} xrange={200}/>
                <Chart data={data} title={'Brake Pedal [V]'} ymin={0} ymax={5} xrange={2000}/>
                <Chart data={engineRpm} title={'Engine RPM'} ymin={0} ymax={5000} xrange={200}/>
                <Chart data={engineThrottle} title={'Engine Throttle [%]'} ymin={0} ymax={100} xrange={200}/>
                <Chart data={vehicleSpeed} title={'Vehicle Speed(MPH) [km/h]'} ymin={0} ymax={200} xrange={200}/>
            </div>
        </>
    )
};

export default RealTimeEdr;
