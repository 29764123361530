import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import '../styles/pages/main.css';
import Nav from '../components/nav';
import Chart from '../components/chart';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import parseDateString from '../module/utils';


const CONTRACT_ADDRESS = '0xc2887c412905eF5e395B319e3E9f20C79F4E14e8';
const ABI = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "string",
                "name": "ipfsHash",
                "type": "string"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "IPFSHashAdded",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "ipfsEntries",
        "outputs": [
            {
                "internalType": "string",
                "name": "ipfsHash",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "userIpfsEntries",
        "outputs": [
            {
                "internalType": "string",
                "name": "ipfsHash",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_ipfsHash",
                "type": "string"
            }
        ],
        "name": "setHash",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getIPFSEntriesCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
            }
        ],
        "name": "getUserIPFSEntriesCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
            }
        ],
        "name": "getHash",
        "outputs": [
            {
                "internalType": "string[]",
                "name": "",
                "type": "string[]"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    }
]; // YOUR CONTRACT ABI

function Main() {
    const [data, setData] = useState([]);
    const [web3, setWeb3] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [myWalletAddress, setMyWalletAddress] = useState("");
    // 날짜 선택을 위한 상태 변수
    const [dateRange, setDateRange] = useState([new Date() - 1000 * 60 * 60 * 24 * 7, new Date()]);
    const [startDate, endDate] = dateRange;

    const connectToKaikas = async () => {
        if (window.klaytn) {
            try {
                const accounts = await window.klaytn.enable();
                // console.log("Connected accounts: ", accounts);
                const web3Instance = new Web3(window.klaytn);
                setWeb3(web3Instance);
                setIsConnected(!!accounts.length);
                setMyWalletAddress(accounts[0]);
                // window.klaytn.on('accountsChanged', () => {
                //     window.location.reload();
                // });
            } catch (error) {
                console.error("Failed to connect Kaikas", error);
                setIsConnected(false);
            }
        } else {
            console.log('No Kaikas extension detected!');
            setIsConnected(false);
        }
    };

    const disconnectKaikas = () => {
        // window.klaytn.disable(); // Or any logic to disconnect Kaikas
        setWeb3(null);
        setData([]);
        setIsConnected(false);
    };

    useEffect(() => {
        // Kaikas 연동
        if (window.klaytn) {
            // Kaikas의 BApp용 provider 사용
            const web3Instance = new Web3(window.klaytn);
            setWeb3(web3Instance);
            if (window.klaytn.isConnected()) {
                setIsConnected(true);
                setMyWalletAddress(window.klaytn.selectedAddress);
            }

            // Kaikas 계정 변경 시 화면 갱신
            // window.klaytn.on('accountsChanged', () => {
            //     window.location.reload();
            // });
            window.klaytn.on('accountsChanged', (accounts) => {
                if (accounts.length) {
                    setMyWalletAddress(accounts[0]);
                } else {
                    setIsConnected(false);
                }
            });
        } else {
            console.log('No Kaikas extension detected!');
        }
    }, []);


    useEffect(() => {
        const fetchDataFromContract = async () => {
            if (!web3) return;

            try {
                const accounts = await window.klaytn.enable();  // Kaikas에서 사용자 주소 얻기
                // console.log("Fetch data for account: ", accounts[0]);
                const contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);

                // Get hash array from contract
                let hashArray = await contract.methods.getHash(accounts[0]).call();
                // 첫 번째와 두 번째 요소를 제외한 나머지 배열
                hashArray = hashArray.slice(2);
                // console.log("Hash Array from Contract: ", hashArray);

                // Fetch data from IPFS using hash and update state
                const dataPromises = hashArray.map(hash => fetchDataFromIPFS(hash));
                const results = await Promise.all(dataPromises);

                // 필터링하여 null 값을 제거하고 오류가 없는 데이터만 상태에 설정
                const validData = results.filter(item => item !== null);

                // 데이터를 시간순으로 정렬
                validData.sort((b, a) => {
                    // "YYYY.MM.DD_HH:mm:ss" 형식의 문자열을 날짜 객체로 파싱
                    const timeA = a.time.split('_');
                    const partsA = timeA[0].split('.').map(Number);
                    const dateTimeA = new Date(partsA[0] + 2000, partsA[1] - 1, partsA[2], ...timeA[1].split(':').map(Number));

                    const timeB = b.time.split('_');
                    const partsB = timeB[0].split('.').map(Number);
                    const dateTimeB = new Date(partsB[0] + 2000, partsB[1] - 1, partsB[2], ...timeB[1].split(':').map(Number));

                    return dateTimeA - dateTimeB;
                });



                setData(validData);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        if (web3) {
            fetchDataFromContract();
        }
        // if (web3) {
        //   fetchDataFromContract(); // 컴포넌트 마운트 시 즉시 데이터를 한 번 가져옵니다.
        //   const intervalId = setInterval(fetchDataFromContract, 1000); // 5초마다 fetchDataFromContract 함수를 반복 호출합니다.

        //   return () => clearInterval(intervalId); // 컴포넌트가 언마운트되면 인터벌을 정리합니다.
        // }
    }, [web3, startDate, endDate]);



    const fetchDataFromIPFS = async (hash) => {
        try {
            const response = await axios.get(`https://gateway.pinata.cloud/ipfs/${hash}`);
            // console.log(`Data fetched from IPFS for hash ${hash}: `, response.data); 
            return response.data;
        } catch (error) {
            console.error(`Error fetching data for hash: ${hash}`, error);
            return null;
        }
    };


    return (
        <div className="App">
            <Nav isConnected={isConnected} connectToKaikas={connectToKaikas} disconnectKaikas={disconnectKaikas} myWalletAddress={myWalletAddress} />
            <div className="titleContainer">
                <h3 className="subtitle">DASHBOARDS</h3>
                <h1 className="title">Overview</h1>
            </div>
            <Chart data={data} title={'BRAKE REAL-TIME CHART [V]'} ymin={0} ymax={5} xrange={2000}/>
            <div className="divider">
                <h3 className="subtitle">BRAKE REAL-TIME TABLE</h3>
                <div className="datePickerContainer">
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        isClearable={true} 
                        style={{
                            borderRadius: '10px', // 테두리 둥근 모서리
                            padding: '10px', // 패딩
                            // 추가적인 스타일 속성
                        }}
                    />
                </div>
            </div>
            <table className="tableContainer" key={data.length}>

                <thead>
                    <tr>
                        <th>발생 시각</th>
                        <th>전압</th>
                        <th>브레이크 여부</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>
                                {
                                    row.time instanceof Date
                                        ? `${row.time.getFullYear().toString().slice(-2)}.${(row.time.getMonth() + 1).toString().padStart(2, '0')}.${row.time.getDate().toString().padStart(2, '0')}_${row.time.getHours().toString().padStart(2, '0')}:${row.time.getMinutes().toString().padStart(2, '0')}:${row.time.getSeconds().toString().padStart(2, '0')}`
                                        : row.time
                                }
                            </td>

                            <td>
                                {row.voltage === 0
                                    ? '0'
                                    : row.voltage.toFixed(7).length > 10
                                        ? `${row.voltage.toFixed(7).substring(0, 10)}...`
                                        : row.voltage.toFixed(7)
                                }
                            </td>
                            {row.voltage > 3 ? <td>ON</td> : <td>OFF</td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

}

export default Main;
