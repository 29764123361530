import React, { useState } from 'react';
import styles from '../styles/components/nav.module.css'
import homeIcon from '../assets/icon/home_icon.svg';
import { useNavigate } from 'react-router-dom';
import HambergerNavigation from './hamburger';

function Nav({ isConnected, connectToKaikas, disconnectKaikas, myWalletAddress }) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function getShortAddress(address) {
    if(!address) return '';
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  }

  async function copyAddress() {
    await navigator.clipboard.writeText(myWalletAddress);
    await alert('주소가 복사되었습니다');
  }

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function closeMenu() {
    disconnectKaikas();
    setIsMenuOpen(false);
  }

  return (
    <div>
      <div className={styles.navbar}>
        <div className={styles.navbarContainer}>
          <div className={styles.navbarLogo}>
            <div className={styles.logo}>
              <img src={homeIcon} alt="Home" onClick={() => navigate('/')} />
            </div>
          </div>
          <div className={styles.navbarMenuContainer}>
            {isConnected ? (
              <button className={styles.loginButton} onClick={() => toggleMenu()}>{getShortAddress(myWalletAddress)}</button>
            ) : (
              <button className={styles.loginButton} onClick={connectToKaikas}>지갑 연결</button>
            )}
            <HambergerNavigation />
          </div>
          {isMenuOpen && (
            <div className={styles.menu}>
              <div className={styles.menuHeader}>
                연결된 지갑 주소
              </div>
              <div className={styles.menuBody}>
                {getShortAddress(myWalletAddress)}
              </div>
              <div className={styles.menuFooter}>
                <button className={styles.menuFooterButton} onClick={() => copyAddress()}>주소 복사</button>
                <button className={styles.menuFooterButton} onClick={() => closeMenu()}>연결 해제</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


export default Nav;
