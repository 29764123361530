import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import styles from '../styles/components/blockAnimate.module.css';

const BlockAnimate = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current, // container는 ref를 사용하여 연결
      path: 'https://assets10.lottiefiles.com/temp/lf20_iRxzMr.json', // 애니메이션의 경로
      renderer: 'svg', // 렌더링 방식
      loop: true, // 반복 여부
      autoplay: true, // 자동 재생 여부
    });
    // 컴포넌트 언마운트 시 lottie 애니메이션 인스턴스를 파괴
    return () => lottie.destroy();
  }, []);
 
  return <div className={styles.blockContainer} ref={container}></div>;
};

export default BlockAnimate;
