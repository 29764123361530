import ApexCharts from 'react-apexcharts';
import React from 'react';
import styles from '../styles/components/chart.module.css';

function Chart({ data, title, ymin, ymax, xrange }) {
    // data 배열의 각 요소를 ApexCharts 형식으로 변환합니다.
    let seriesData = data.map(item => {
        let date = item.time;
        // 만약 item.time의 타입이 문자열이면
        if (typeof item.time === 'string') {
            // "YY.MM.DD_HH:mm:ss" 형식의 문자열을 Date 객체로 파싱합니다.)
            const parts = item.time.split('_');
            const dateParts = parts[0].split('.').map(Number);
            const timeParts = parts[1].split(':').map(Number);
            // Date 객체를 만듭니다. 연도는 2000을 더해줍니다.
            date = new Date(Date.UTC(dateParts[0] + 2000, dateParts[1] - 1, dateParts[2], ...timeParts));
        }

        return {
            x: date.getTime(), // Date 객체의 타임스탬프를 가져옵니다.
            y: item.voltage.toFixed(5)
        };
    });

    // seriesData.sort((a, b) => {
    //     return a.x - b.x;
    // });
    
    // console.log(seriesData);


    const options = {
        series: [{
            data: seriesData.reverse(),
        }],
        chart: {
            id: 'realtime',
            height: 350,
            type: 'line',
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        markers: {
            size: 0
        },
        xaxis: {
            type: 'datetime',
            range: 60 * xrange,
        },
        yaxis: {
            min: ymin,
            max: ymax,
        },
        legend: {
            show: false
        },
    };

    return (
        <div id="chart">
            <div className={styles.title}>{title}</div>
            <ApexCharts options={options} series={options.series} type="line" height={350} />
        </div>
    );
}

export default Chart;

